import { LinkBase } from '../../atoms/Link/LinkBase'
import { hoverStyleColorRed } from '../../styles/hover'

interface Props {
  serviceName: string
  imagePathPc: string
  imagePathSp: string
  title: string
  content: string
  groupList: Array<{ text: string; url: string }>
  columnNumber: number
}

/**
 * カード、事業案内詳細で使用
 * pcは高さ固定部分あり
 * @param {string} serviceName
 * @param {string} imagePathPc
 * @param {string} imagePathSp
 * @param {string} title
 * @param {string} content
 * @param {Array} groupList
 * @param {number} columnNumber
 */
/** */
export const CardService = ({
  serviceName,
  imagePathPc = 'common/default.png',
  imagePathSp = 'common/default.png',
  title,
  content,
  groupList,
  columnNumber = 2,
}: Props) => {
  return (
    <li className="relative w-full list-none bg-white border-border10 border">
      <div className="hidden pc:block pc:h-[210px] pc:w-full relative">
        <img
          src={imagePathPc || '/common/img/default.png'}
          alt=""
          className="object-cover absolute w-full h-full"
        />
      </div>
      <div className="pc:hidden text-center pt-[55.1%] w-full relative">
        <img
          src={imagePathSp || '/common/img/default.png'}
          alt=""
          className="object-cover top-0 left-0 absolute w-full h-full"
        />
      </div>
      <div className={columnNumber === 2 ? `pt-6 px-6 pb-5` : `pt-5`}>
        <b className="text-primary font-bold text-md">{serviceName}</b>
        <h3 className="font-bold text-lg pc:h-[52px] leading-tight mt-2">
          {title}
        </h3>
        <p className="text-sm leading-normal pc:h-[120px] mt-2">{content}</p>
        <div className="bg-bg10 mt-4 p-4">
          <b className="text-sm font-bold">関連グループ会社</b>
          <ul
            className="mt-3 space-y-2
          "
          >
            {groupList.map((group, i) => (
              <li key={i}>
                <LinkBase
                  href={group.url}
                  css={hoverStyleColorRed}
                  className="inline-flex items-center space-x-2"
                >
                  <span className="text-sm leading-tight">{group.text}</span>
                  <svg
                    width="8"
                    height="6"
                    viewBox="0 0 8 6"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 3L0.199999 5.59808L0.2 0.401924L8 3Z"
                      fill="#CC050B"
                    />
                  </svg>
                </LinkBase>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </li>
  )
}
