import React from 'react'
import { HeadingSecondary } from '../../atoms/Heading/HeadingSecondary'
import { layoutList } from '../../styles/layout'
import { Breadcrumb } from '../../molecules/Breadcrumb/Breadcrumb'
import { MvBgImg } from './MvBgImg'

interface Props {
  heading: string
  headingEnglish: string
  BreadcrumbData: Array<{ title: string; url: string }>
  catchCopy: string
}

/**
 * 下層ページのファーストビュー、キャッチコピー付き（事業案内下層で使用）
 */
/** */
export const MvChildPageWithCatchCopy = ({
  heading,
  headingEnglish,
  BreadcrumbData,
  catchCopy,
}: Props) => {
  return (
    <>
      <MvBgImg />
      <div className="pb-14" css={layoutList.layoutInner}>
        <div className="pt-[88px] pc:pt-[80px] pb-10">
          <HeadingSecondary label={heading} EnglishLabel={headingEnglish} />
        </div>
        <p className="text-sm pc:text-md leading-normal pb-10">{catchCopy}</p>
        <Breadcrumb dataList={BreadcrumbData} />
      </div>
    </>
  )
}
