import React, { ReactNode } from 'react'
import { SideNav } from '../SideNav/SideNav'
import { layoutList } from '../../styles/layout'
import { sideNavDataService } from '../../data/sideNavData'
import { MvChildPageWithCatchCopy } from '../Mv/MvChildPageWithCatchCopy'
import { LayoutRoot } from './LayoutRoot'

interface Props {
  children: ReactNode
  heading: string
  headingEnglish: string
  catchCopy: string
}

/**
 * 事業案内下層ページのレイアウト
 * @param {ReactNode} children
 * @param {string} heading
 * @param {string} headingEnglish
 * @param {string} catchCopy
 */
/** */
export const LayoutService = ({
  children,
  heading,
  headingEnglish,
  catchCopy,
}: Props) => {
  return (
    <LayoutRoot>
      <MvChildPageWithCatchCopy
        heading={heading}
        headingEnglish={headingEnglish}
        BreadcrumbData={[
          { title: '事業案内', url: '/service' },
          { title: heading, url: '' },
        ]}
        catchCopy={catchCopy}
      />
      <div
        css={layoutList.layoutInner}
        className="flex justify-between flex-col pc:flex-row pc:items-start"
      >
        <div className="pc:w-[932px] pb-14 relative">{children}</div>
        <div className="pc:w-[212px] w-full">
          <SideNav heading={'事業案内'} linkList={sideNavDataService} />
        </div>
      </div>
    </LayoutRoot>
  )
}
